const Prices = {
  Helper_Gra: { 0: '0' },
  Helper_Conf: { 0: 9 },
  Helper_Zen: { 0: 139 },
  Pro_Gra: { 0: '0' },
  Pro_Asso: { 1: 39, 2: 10, 3: 15, 4: 20, 5: 25, 6: 30, 11: 50, 26: 100, 51: 150 },
  Pro_Pro: { 1: '', 2: 40, 3: 60, 4: 80, 5: 100, 6: 120, 11: 200, 26: 400, 51: 600 },
  Partner_Coordination: {
    0: 0,
    10000: 300,
    15000: 500,
    50000: 1500,
    100000: 3000,
    250000: 6500,
    400000: 10000,
    500000: 10500,
  },
  Partner_Services: {
    0: 0,
    10000: 600,
    15000: 1000,
    50000: 3000,
    100000: 6000,
    250000: 13000,
    400000: 20000,
    500000: 21000,
  },
  Partner_Care: {
    0: 0,
    10000: 1200,
    15000: 2000,
    50000: 6000,
    100000: 12000,
    250000: 26000,
    400000: 40000,
    500000: 42000,
  },
};

const PRICING_DATA = {
  Helpers: {
    value: 'Helpers',
    tab: 'aidants',
    // Slider infos
    hasSlider: false,
    // Table info
    columnColors: ['#FAFBFC', '#F6F8FA', '#DEE0E4'],
    columnHeaders: [
      { title: 'Gratuit', prices: Prices.Helper_Gra, frequency: '/mois', round: 1 },
      {
        title: 'Confort',
        prices: Prices.Helper_Conf,
        frequency: '/mois',
        round: 1,
        btnLabel: 'Nous contacter',
      },
      {
        title: 'Zen',
        prices: Prices.Helper_Zen,
        frequency: '/mois',
        round: 1,
        btnLabel: 'Nous contacter',
      },
    ],
    rowGroups: [
      {
        groupTitle: 'Compte',
        rows: [
          {
            title: 'Compte aidant',
            subtitle:
              'Création de votre compte : personnalisation de votre profil et celui de votre proche, fil d’actualités dédié à votre famille (photos, nouvelles, etc.)',
            cells: [
              'Hébergement données de santé',
              'Hébergement données de santé',
              'Hébergement données de santé',
            ],
          },
          {
            title: 'Gestion des membres',
            subtitle:
              'Choisissez le réseau d’intervenants avec votre proche : famille, amis, voisins, service à la personne, infirmiers, etc.',
            cells: [true, true, true],
          },
          {
            title: 'Accès pour le proche aidé',
            subtitle:
              'Une application dédiée pour le proche aidé, avec des fonctionnalités simplifiées. Une tablette au domicile de votre proche pour qu’il accède aux photos envoyées par son entourage, à une vision simplifiée du planning, etc.',
            // sublines: [
            //   [
            //     'Une application dédiée pour le proche aidé, avec des fonctionnalités simplifiées.',
            //     '',
            //     '9 €/ mois',
            //     'inclus',
            //   ],
            //   [
            //     'Une tablette au domicile de votre proche pour qu’il accède aux photos envoyées par son entourage, à une vision simplifiée du planning, etc.',
            //     '',
            //     '29 € / mois',
            //     '+ 29 € / mois',
            //   ],
            // ],
            cells: [true, true, true],
          },
        ],
      },
      {
        groupTitle: 'Carnet de liaison',
        rows: [
          {
            title: 'Agenda',
            subtitle:
              'Un planning partagé des activités de son proche : consulter les activités de la journée ou semaine, savoir qui fait quoi et quand, ajouter des tâches, modifier les intervenants.',
            cells: [true, true, true],
          },
          {
            title: 'Échanges Quotidiens',
            subtitle:
              'Un espace commun d’échanges et de partage avec l’ensemble des intervenants professionnels et informels, pour une meilleure coordination autour de son proche.',
            cells: [true, true, true],
          },
          {
            title: 'Échanges Santé',
            subtitle:
              'Un espace de partage et d’échanges à propos des sujets de santé autour de son proche. L’accès à ces messages est sécurisé et strictement réservé aux personnes habilitées.',
            cells: [true, true, true],
          },
          {
            title: 'Documents',
            subtitle:
              'Un espace de partage de documents à propos de son proche, pour éviter de tout le temps répéter les mêmes choses ;-). L’accès à certains documents peut être réservé à des personnes habilitées.',
            cells: ['100Mo', '1Go', '5Go'],
          },
          {
            title: 'Informations patient',
            subtitle:
              'Une vision 360° de son proche : renseignez les informations pratiques (code de la porte, etc.) et le nom des intervenants ainsi que leurs rôles.',
            cells: [true, true, true],
          },
          {
            title: 'Notifications et alertes professionnelles',
            subtitle:
              'Un système de notifications paramétrables en fonction du niveau d’importance des messages et en fonction du profil des intervenants.',
            cells: [true, true, true],
          },
        ],
      },
      {
        groupTitle: 'Accompagnement',
        rows: [
          {
            title: 'Care manager',
            subtitle:
              'Des droits d’accès partagés avec le care manager pour garantir une bonne coordination des intervenants et une fluidité de communication.',
            cells: [false, true, '2h / mois'],
          },
        ],
      },
      {
        groupTitle: 'Support',
        rows: [
          {
            title: 'Maintenance',
            subtitle: 'Maintenance évolutive et corrective.',
            cells: [true, true, true],
          },
          {
            title: 'Assistance',
            subtitle:
              'Un support disponible par mail ou téléphone, du lundi au vendredi, de 8h30 à 18h30.',
            cells: [false, false, true],
          },
        ],
      },
    ],
  },
  Pros: {
    value: 'Pros',
    tab: 'pros',
    // Slider infos
    hasSlider: false,
    sliderTitle: 'équipes autonomes',
    minSliderValue: 1,
    maxSliderValue: 100,
    defaultSliderValue: 10,
    sliderStep: 1,
    sliderSubtitles: [
      {
        value: 1,
        label: 'Petite structure',
      },
      {
        value: 100,
        label: 'Grande structure',
      },
    ],
    // Table info
    columnColors: ['#FDFDFE', '#FAFBFC', '#F6F8FA'],
    columnHeaders: [
      {
        title: 'Gratuit',
        subtitle: ' ',
        prices: Prices.Pro_Gra,
        frequency: '/mois',
        round: 1,
      },
      {
        title: 'Inclusif',
        subtitle: 'Association, Plateforme de répit, CCAS…',
        prices: Prices.Pro_Asso,
        frequency: '/mois',
        round: 1,
        btnLabel: 'Nous contacter',
      },
      {
        title: 'Care',
        subtitle: 'ESMS, EPHAD, SAMSAH, SAVS, ESAT, Care manager…',
        prices: Prices.Pro_Pro,
        frequency: 'tarif /mois /carnet',
        round: 1,
        btnLabel: 'Nous contacter',
      },
    ],
    rowGroups: [
      {
        groupTitle: 'Vos services en ligne',
        rows: [
          {
            title: 'Page d’accueil',
            subtitle:
              'Une page dédiée à votre structure intégrant une présentation, vos coordonnées, une photo personnalisée et surtout un fil d’actualités pour informer et interagir avec votre public.',
            cells: [true, true, true],
          },
          {
            title: 'Service en ligne',
            subtitle:
              'Les bénéficiaires peuvent faire directement des demandes en ligne (selon les services choisis par l’administrateur de votre structure). Pratique !',
            cells: [true, true, true],
          },
          {
            title: 'Activités en ligne',
            subtitle:
              'Mettez en ligne vos activités de loisirs ou prévention, avec de jolies photos pour donner envie. Votre programme est toujours à jour et les bénéficiaires peuvent même poser des questions et s’inscrire en ligne !',
            cells: [true, true, true],
          },
          {
            title: 'Groupes de discussion',
            subtitle:
              'Créez des groupes de discussion pour favoriser l’entraide, le partage, la pair-aidance (par exemple des communautés d’aidants autour d’une pathologie spécifique). Choisissez les membres de chaque groupe parmi tous vos membres (professionnels, aidants, bénévoles, etc.)',
            cells: ['3', 'illimité', 'illimité'],
          },
          {
            title: 'Fonctionnalités administrateur',
            subtitle:
              'Gérez les membres de votre structure (invitation, validation, profils, habilitations, etc.), choisissez les types de services et d’activités disponibles en ligne et créez des groupes de discussion.',
            cells: [true, true, true],
          },
          {
            title: 'Transmission et suivi internes',
            subtitle:
              'Un fil de discussion dédié aux échanges au sein de chaque équipe professionnelle, il n’inclut pas les aidants ou bénéficiaires membres de la structure.',
            cells: ['10', 'illimité', 'illimité'],
          },
          {
            title: 'Agenda équipe partagé',
            subtitle:
              'Organisez le planning des interventions de vos équipes et de vos bénéficiaires. Export vers excel.',
            cells: [true, true, true],
          },
        ],
      },
      {
        groupTitle: 'Carnet de liaison',
        rows: [
          {
            title: 'Création de carnet de liaison',
            subtitle:
              'Créez un espace d’échanges mutualisé avec l’entourage de la personne et avec les autres professionnels. Indispensable pour coordonner les actions et partager les infos !',
            cells: [false, false, 'tarif /mois /carnet'],
          },
          {
            title: 'Agenda',
            subtitle:
              'Accès au planning du bénéficiaire : consulter les activités de la journée ou semaine, savoir qui fait quoi et quand, ajouter des tâches, modifier les intervenants.',
            cells: [true, true, true],
          },
          {
            title: 'Échanges quotidien',
            subtitle:
              'Un espace commun d’échanges et de partage avec l’ensemble des intervenants professionnels et informels, pour une meilleure coordination autour d’une personne à domicile.',
            cells: [true, true, true],
          },
          {
            title: 'Échanges santé',
            subtitle:
              'Un espace de partage et d’échanges à propos des sujets de santé autour d’un patient. L’accès à ces messages est strictement réservé aux membres habilités.',
            cells: [false, true, true],
          },
          {
            title: 'Documents',
            subtitle:
              'Un espace de partage de documents à propos de la personne à domicile, pour éviter de tout le temps redemander les mêmes choses ;-). L’accès à certains documents peut être réservé aux personnes habilitées.',
            cells: [false, true, true],
          },
          {
            title: 'Informations patient',
            subtitle:
              'Une vision 360° pour connaître la situation du patient, les informations pratiques (code de la porte, etc.) et le nom des intervenants ainsi que leurs rôles.',
            cells: [false, true, true],
          },
          {
            title: 'Notifications et alertes',
            subtitle:
              'Recevez des notifications pour ne pas passer à côté d’une information importante. Les notifications sont paramétrables en fonction de chaque profil.',
            cells: [false, true, true],
          },
        ],
      },
      {
        groupTitle: 'Statistiques et analyse d’impact',
        rows: [
          {
            title: 'Statistiques',
            subtitle:
              'Accès aux statistiques d’utilisation de la plateforme : nombre et types d’utilisateurs, flux d’activités, etc.',
            cells: [false, true, true],
          },
          {
            title: 'Dashboard',
            subtitle:
              'Accès à des outils de pilotage « intelligents » pour détecter des changements d’habitudes, analyser les flux (fréquence et type des échanges...) et alerter si besoin.',
            cells: [false, false, true],
          },
          {
            title: 'SEO internet et recherche en ligne',
            subtitle:
              'Un référencement optimisé de votre page d’accueil, de vos services et de vos activités pour les moteurs de recherches.',
            cells: [false, false, true],
          },
        ],
      },
      {
        groupTitle: 'Accompagnement',
        rows: [
          {
            title: 'Formation',
            subtitle:
              'Temps de formation pour les administrateurs : paramétrage de la structure, des services et des activités, gestion des membres (invitation, validation, types de profils...), administration des groupes de discussion, accès au backoffice pro.',
            cells: [
              false,
              'Prise en main (env. 1/2 journée - 400 €)',
              'Reprise des données (env. 2 jours - 1500 €)',
            ],
          },
        ],
      },
      {
        groupTitle: 'Support',
        rows: [
          {
            title: 'Maintenance',
            subtitle: 'Maintenance évolutive et corrective.',
            cells: [true, true, true],
          },
          {
            title: 'Assistance',
            subtitle:
              'Un support disponible par mail ou téléphone, du lundi au vendredi, de 8h30 à 18h30.',
            cells: [false, true, 'Traitement prioritaire'],
          },
        ],
      },
    ],
  },
  Partners: {
    value: 'Partners',
    tab: 'partenaires',
    // Slider infos
    hasSlider: false,
    sliderTitle: 'bénéficiaires',
    minSliderValue: 10000,
    maxSliderValue: 500000,
    defaultSliderValue: 50000,
    sliderStep: 100,
    sliderSubtitles: [
      {
        value: 100,
        label: 'Commune',
      },
      {
        value: 250000,
        label: 'Petit département',
      },
      {
        value: 500000,
        label: 'Grand département',
      },
    ],
    // Table info
    columnColors: ['#FDFDFE', '#FAFBFC', '#F6F8FA'],
    columnHeaders: [
      {
        title: 'Information',
        subtitle: 'Plateforme de service en marque blanche. Reférentiel de structure',
        prices: Prices.Partner_Coordination,
        noPrice: 'Accompagnement sur mesure',
        round: 100,
        btnLabel: 'Nous contacter',
      },
      {
        title: 'Coopération',
        subtitle:
          'Plateforme de service en marque blanche. Mise en oeuvre de la coopération et du care management',
        prices: Prices.Partner_Services,
        noPrice: 'Accompagnement sur mesure',
        round: 100,
        btnLabel: 'Nous contacter',
      },
      {
        title: 'Impact',
        subtitle:
          'Plateforme en marque blanche. Mise en oeuvre de la coopération et mesure d’impact sur le territoire',
        prices: Prices.Partner_Care,
        noPrice: 'Accompagnement sur mesure',
        round: 100,
        btnLabel: 'Nous contacter',
      },
    ],
    rowGroups: [
      {
        groupTitle: 'Plateforme',
        rows: [
          {
            title: 'Plateforme de services en marque blanche',
            subtitle:
              'Un nom de domaine dédié à votre plateforme, une landing page personnalisée selon votre thématique et le public cible.',
            cells: [true, 'Hébergement données de santé', 'Hébergement données de santé'],
          },
          {
            title: 'Demande de services en ligne',
            subtitle:
              'Référencement des services proposés par les structures intégrées à votre plateforme. Les bénéficiaires peuvent faire directement des demandes en ligne. Les professionnels y accèdent via leur backoffice.',
            cells: ['Consultation', true, true],
          },
          {
            title: 'Activités en ligne',
            subtitle:
              'Référencement des activités proposées par les structures intégrées à votre plateforme. Les bénéficiaires peuvent s’inscrire en ligne.',
            cells: ['Consultation', true, true],
          },
          {
            title: 'Articles en ligne',
            subtitle:
              'Référencement des articles proposés par les structures intégrées à votre plateforme. Les articles peuvent être publics ou privés.',
            cells: ['Consultation', true, true],
          },
          {
            title: 'Communautés d’aidants',
            subtitle:
              'Donnez accès aux communautés dédiées à certaines pathologies ou certaines problématiques.',
            cells: ['Consultation', true, true],
          },
          {
            title: 'Formations et Moocs en ligne',
            subtitle:
              'Donnez accès aux formations et moocs dédiées à certaines pathologies ou certaines problématiques. ',
            cells: ['Consultation', true, true],
          },
          {
            title: 'Mutualisation de ressources entre les structures',
            subtitle:
              'Un espace pour partager les documents, les informations, ou toute ressource intéressante',
            cells: ['Consultation', true, true],
          },
        ],
      },
      {
        groupTitle: 'Carnet de liaison',
        rows: [
          {
            title: 'Accès au carnet de liaison',
            subtitle:
              'Offrez l’accès au carnet de liaison aux professionnels de santé, aux structures médico-sociales, aux associations de lien social, etc. intégrées dans la plateforme : un outil au service de la coopération des acteurs.',
            cells: [false, true, true],
          },
        ],
      },
      {
        groupTitle: 'Analyse d’impact',
        rows: [
          {
            title: 'SEO Internet et recherche en ligne',
            subtitle:
              'Un référencement optimisé sur les moteurs de recherche (services, actualités, activités…).',
            cells: [true, true, true],
          },
          {
            title: 'Statistiques',
            subtitle:
              'Accès aux statistiques d’utilisation de la plateforme : nombre et types d’utilisateurs, flux d’activités, type de recherche effectuées...',
            cells: [false, false, true],
          },
          {
            title: 'Analyse de l’impact',
            subtitle:
              'Des indicateurs pour analyser l’impact de la plateforme numérique sur un public, un territoire, etc. Le numérique au service de l’innovation sociale.',
            cells: [false, false, true],
          },
          {
            title: 'Dashboard',
            subtitle:
              'Accès à des outils de pilotage « intelligents » pour détecter les besoins de son public cible, communiquer plus finalement et adpater les offres.',
            cells: [false, false, true],
          },
        ],
      },
      {
        groupTitle: 'Accompagnement',
        rows: [
          {
            title: 'Reprise des services',
            subtitle:
              'Intégration dans la plateforme des services que vous souhaitez mettre en valeur, ateliers de co-construction avec les parties prenantes et les utilisateurs.',
            cells: ['Sur mesure', 'Sur mesure', 'Sur mesure'],
          },
          {
            title: 'Reprise des données',
            subtitle:
              'Intégration et catégorisation des données (structures, services, activités, etc.), adaptation des filtres de recherche, etc.',
            cells: ['Sur mesure', 'Sur mesure', 'Sur mesure'],
          },
          {
            title: 'Community manager dédié',
            subtitle:
              'Un travailleur social 2.0 dont la mission est de faire vivre et évoluer la plateforme et de garantir des informations à jour : motiver les professionnels, les structures médico-sociales, les associations à intégrer la plateforme, favoriser l’usage, lever les freins, etc.',
            cells: [false, false, true],
          },
        ],
      },
      {
        groupTitle: 'Support',
        rows: [
          {
            title: 'Maintenance',
            subtitle: 'Maintenance évolutive et corrective.',
            cells: [true, true, true],
          },
          {
            title: 'Assistance',
            subtitle:
              'Un support disponible par mail ou téléphone, du lundi au vendredi, de 8h30 à 18h30.',
            cells: [false, true, true],
          },
        ],
      },
    ],
  },
};

export default PRICING_DATA;
