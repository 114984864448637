import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconCheck from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SectionInfo from '../../Components/PagesLeagues/SectionInfo';
import Seo from '../../Routing/Seo';
import { Images } from '../../Themes';
import PRICING_DATA from '../../Themes/PricingData';
import SectionSlider from '../../Components/PagesLeagues/SectionSlider';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layout: {
    backgroundColor: 'white',
    paddingTop: '100px',
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    justifyContent: 'center',
    [theme.breakpoints.up(800 + theme.spacing(6))]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'white',
    },
  },
  headerContainer: {
    backgroundColor: 'white',
  },
  tableContainer: {
    backgroundColor: 'white',
  },
  overflow: {
    [theme.breakpoints.down('xs')]: {
      overflowX: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      overflowX: 'initial',
    },
  },
  partnerContainer: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    backgroundColor: '#FAFAFA',
  },
  bold: {
    fontWeight: '800',
    paddingRight: theme.spacing(1),
  },
  pageTitle: {
    marginTop: '4rem',
    paddingLeft: '6rem',
    paddingRight: '6rem',
  },
  pageSubTitle: {
    fontSize: '1.3rem',
  },
  partenaireTarifs: {
    width: '100%',
    height: 130,
  },
  tabs: { width: '100%' },
  indicator: { backgroundColor: 'black' },
  tab: {
    textTransform: 'none',
    fontSize: '1.2rem',
    color: 'black',
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
    width: '33%',
    [`${theme.breakpoints.up('sm')} and (orientation: landscape)`]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  tabSelected: {
    '&>*': {
      fontWeight: '800',
    },
  },
  page: {
    width: '100%',
    paddingTop: theme.spacing(9),
  },
  sliderContent: { textAlignLast: 'center' },
  sliderTitle: {
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
    fontSize: '1rem',
    paddingBottom: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      fontSize: '1.4rem',
    },
  },
  thumb: {
    width: '26px',
    height: '26px',
    marginTop: '-13px',
  },
  track: { height: '3px' },
  mark: { fontSize: '0.95rem' },
  root: {
    width: '70%',
    paddingBottom: theme.spacing(8),
  },
  columnHeader: {
    paddingBottom: theme.spacing(1),
    fontWeight: '800',
    fontSize: '1.2rem',
    height: 52,
  },
  price: {
    flexDirection: 'row',
    display: 'flex',
    fontSize: '1rem',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  pricePerElement: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
  },
  checkIcon: { color: green[600] },
  cell: {
    border: 'none',
    minWidth: '12rem',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  table: {
    borderSpacing: '8px 0',
    borderCollapse: 'unset',
    display: 'block',
  },
  subtitle: { marginTop: theme.spacing(-0.5), paddingBottom: theme.spacing(1) },
  row: { verticalAlign: 'top' },
  btn: {
    marginTop: theme.spacing(2),
  },
}));

const getPrice = (x, prices) => {
  if (x === null || x < 0 || !prices) return [-1, -1];

  const stepKeys = Object.keys(prices);
  const foundKey = stepKeys.find(
    (key, i) => x === key || i === stepKeys.length - 1 || x < stepKeys[i + 1],
  );

  if (foundKey) return [prices[foundKey], x > 0 ? prices[foundKey] / x : 0];
  return [-1, -1];
};

const getTabValue = tab => {
  switch (tab) {
    case 'aidants':
      return PRICING_DATA.Helpers.value;
    case 'pros':
      return PRICING_DATA.Pros.value;
    case 'partenaires':
      return PRICING_DATA.Partners.value;
    default:
      return PRICING_DATA.Helpers.value;
  }
};

const Pricing = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const queryParam = new URLSearchParams(location.search);
  const selectedOffer = queryParam.get('offre');
  const [currentTabValue, setCurrentTabValue] = React.useState(getTabValue(selectedOffer));
  const previousTabValue = React.useRef();
  const [sliderValue, setSliderValue] = React.useState(0);

  React.useEffect(() => {
    previousTabValue.current = currentTabValue;
    if (PRICING_DATA[currentTabValue].hasSlider)
      setSliderValue(PRICING_DATA[currentTabValue].defaultSliderValue);
  }, [currentTabValue]);

  const [helperSlideDir, proSlideDir, partnerSlideDir] = React.useMemo(() => {
    if (
      currentTabValue === PRICING_DATA.Helpers.value ||
      (currentTabValue === PRICING_DATA.Pros.value &&
        previousTabValue.current === PRICING_DATA.Helpers.value)
    ) {
      return ['right', 'left', 'left'];
    }

    return ['right', 'right', 'left'];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTabValue]);

  const renderSlider = tab => (
    <div className={classes.sliderContent}>
      <Typography id="slider-title" gutterBottom className={classes.sliderTitle} align="center">
        <div className={classes.bold}>{`${sliderValue.toLocaleString()}`}</div>
        {t(PRICING_DATA[tab].sliderTitle)}
      </Typography>
      <Slider
        value={sliderValue}
        defaultValue={PRICING_DATA[tab].defaultSliderValue}
        min={PRICING_DATA[tab].minSliderValue}
        max={PRICING_DATA[tab].maxSliderValue}
        onChange={(event, newValue) => {
          setSliderValue(newValue);
        }}
        step={PRICING_DATA[tab].sliderStep}
        marks={PRICING_DATA[tab].sliderSubtitles}
        classes={{
          thumb: classes.thumb,
          track: classes.track,
          markLabel: classes.mark,
          root: classes.root,
        }}
      />
    </div>
  );

  const renderTable = tab => (
    <>
      <TableContainer className={classes.overflow}>
        <Table stickyHeader classes={{ root: classes.table }} aria-label="pricing table">
          <TableHead style={{ position: 'relative' }}>
            <TableRow className={classes.row}>
              <TableCell className={classes.cell} style={{ backgroundColor: '#fff', top: '64px' }}>
                {' '}
              </TableCell>
              {PRICING_DATA[tab].columnHeaders?.map((header, i) => {
                const [total, perElement] = getPrice(sliderValue, header.prices);
                return (
                  <TableCell
                    key={JSON.stringify(header)}
                    align="center"
                    className={classes.cell}
                    style={{
                      backgroundColor: PRICING_DATA[tab].columnColors[i],
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                      top: '64px',
                    }}
                  >
                    <Typography
                      id="column-title"
                      gutterBottom
                      align="center"
                      variant="h4"
                      className={classes.columnHeader}
                    >
                      {header.title}
                    </Typography>
                    {header.subtitle && (
                      <Grid
                        style={{
                          height: '60px',
                        }}
                      >
                        <Typography
                          className={classes.subtitle}
                          id="column-title"
                          gutterBottom
                          align="center"
                          variant="subtitle2"
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          {header.subtitle}
                        </Typography>
                      </Grid>
                    )}
                    <Typography
                      id="price"
                      gutterBottom
                      align="center"
                      variant="body1"
                      className={classes.price}
                    >
                      <div className={classes.bold}>
                        {total ? `${total.toLocaleString()}€` : header.noPrice}
                      </div>
                      {header.frequency}
                    </Typography>
                    {header.priceSubtitle && (
                      <Typography
                        id="column-title"
                        gutterBottom
                        align="center"
                        variant="subtitle2"
                        className={classes.pricePerElement}
                      >
                        <div className={classes.bold}>
                          {`${(
                            Math.round(perElement * header.round) / header.round
                          ).toLocaleString()}€`}
                        </div>
                        {header.priceSubtitle}
                      </Typography>
                    )}
                    {header.btnLabel && (
                      <Button
                        variant="contained"
                        href="mailto:hello@wello.fr"
                        color="primary"
                        className={classes.btn}
                      >
                        {header.btnLabel}
                      </Button>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {PRICING_DATA[tab].rowGroups?.map(rowGroup => (
              <>
                <TableRow key={rowGroup.groupTitle} className={classes.row}>
                  <TableCell component="th" scope="row" className={classes.cell}>
                    <Typography variant="h4" className={classes.bold}>
                      {rowGroup.groupTitle}
                    </Typography>
                  </TableCell>
                  {PRICING_DATA[tab].columnColors.map((col, i) => (
                    <TableCell
                      key={JSON.stringify(col)}
                      className={classes.cell}
                      style={{ backgroundColor: PRICING_DATA[tab].columnColors[i] }}
                    >
                      {' '}
                    </TableCell>
                  ))}
                </TableRow>
                {rowGroup.rows?.map(row => (
                  <CollapsableRow
                    row={row}
                    key={JSON.stringify(row)}
                    colors={PRICING_DATA[tab].columnColors}
                    sliderValue={sliderValue}
                  />
                ))}
              </>
            ))}
            <TableCell className={classes.cell}> </TableCell>
            {PRICING_DATA[tab].columnColors.map((col, i) => (
              <TableCell
                key={JSON.stringify(col)}
                className={classes.cell}
                style={{
                  backgroundColor: PRICING_DATA[tab].columnColors[i],
                  borderBottomLeftRadius: '8px',
                  borderBottomRightRadius: '8px',
                }}
              >
                {' '}
              </TableCell>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <>
      <Helmet>{Seo.title('Wello | Tarifs')}</Helmet>
      <Grid className={classes.mainLayout}>
        <Grid container className={classes.headerContainer}>
          <Grid item xs={12} className={classes.pageTitle}>
            <Typography id="title" gutterBottom align="center" variant="h2">
              Tarifs
            </Typography>
            <Typography id="title" gutterBottom align="center" variant="subtitle1">
              Des tarifs flexibles depuis l’application mobile gratuite pour les aidants jusqu’à la
              plateforme multi-services pour les départements !
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <SectionInfo
              title="Lancement"
              subTitle="Wello est en phase de lancement. Profitez en ce moment des remises exceptionnelles ou contactez nous si vous souhaitez faire évoluer nos offres."
              buttonTitle="Nous contacter"
              buttonUrl="mailto:hello@wello.fr"
              backgroundColor="#0050b3"
            />
          </Grid>

          <Divider style={{ margin: '2rem', background: 'none' }} />
        </Grid>

        <SectionSlider
          title="Nos partenaires financiers"
          subtitle="Acteurs institutionnels, privés ou publics,  ils font le pari de la coopération pour le maintien à domicile et nous accompagnent depuis le début"
          items={[
            {
              img: Images.partner.fin1,
              alt: 'Région Pays de La Loire',
            },
            {
              img: Images.partner.fin2,
              alt: 'France Active',
            },
            {
              img: Images.partner.fin3,
              alt: 'BPI France',
            },
            {
              img: Images.partner.fin4,
              alt: 'MSA',
            },
            {
              img: Images.partner.fin5,
              alt: 'Malakoff humanis',
            },
            {
              img: Images.partner.fin6,
              alt: 'AG2R La Mondiale',
            },
            {
              img: Images.partner.fin7,
              alt: 'Interreg',
            },
            {
              img: Images.partner.fin8,
              alt: 'Département Nord',
            },
            {
              img: Images.partner.fin9,
              alt: 'Réseau Entreprendre',
            },
            {
              img: Images.partner.fin10,
              alt: 'La French Tech',
            },
          ]}
          greyBackground
        />
        <Divider style={{ margin: '4rem', background: 'none' }} />

        <Grid container className={classes.tableContainer}>
          <Grid item md={1} />
          <Grid item md={10} xs={12}>
            <TabContext value={currentTabValue}>
              <Tabs
                value={currentTabValue}
                onChange={(event, newValue) => {
                  setCurrentTabValue(newValue);
                  history.push(`/tarifs?offre=${PRICING_DATA[newValue].tab}`);
                }}
                centered
                aria-label="pricing public tabs"
                classes={{ indicator: classes.indicator, root: classes.tabs }}
              >
                <Tab
                  label={t('WELLO.PRICING.TAB_HELPERS.NAME')}
                  value={PRICING_DATA.Helpers.value}
                  classes={{ root: classes.tab, selected: classes.tabSelected }}
                  disableRipple
                />
                <Tab
                  label={t('WELLO.PRICING.TAB_PROS.NAME')}
                  value={PRICING_DATA.Pros.value}
                  classes={{ root: classes.tab, selected: classes.tabSelected }}
                  disableRipple
                />
                <Tab
                  label={t('WELLO.PRICING.TAB_PARTNERS.NAME')}
                  value={PRICING_DATA.Partners.value}
                  classes={{ root: classes.tab, selected: classes.tabSelected }}
                  disableRipple
                />
              </Tabs>
              <TabPanel value={PRICING_DATA.Helpers.value} className={classes.page}>
                <Slide
                  direction={helperSlideDir}
                  in={currentTabValue === PRICING_DATA.Helpers.value}
                >
                  <Grid>
                    {PRICING_DATA.Helpers.hasSlider && renderSlider(PRICING_DATA.Helpers.value)}
                    {renderTable(PRICING_DATA.Helpers.value)}
                  </Grid>
                </Slide>
              </TabPanel>
              <TabPanel value={PRICING_DATA.Pros.value} className={classes.page}>
                <Slide direction={proSlideDir} in={currentTabValue === PRICING_DATA.Pros.value}>
                  <Grid>
                    {PRICING_DATA.Pros.hasSlider && renderSlider(PRICING_DATA.Pros.value)}
                    {renderTable(PRICING_DATA.Pros.value)}
                  </Grid>
                </Slide>
              </TabPanel>
              <TabPanel value={PRICING_DATA.Partners.value} className={classes.page}>
                <Slide
                  direction={partnerSlideDir}
                  in={currentTabValue === PRICING_DATA.Partners.value}
                >
                  <Grid>
                    {PRICING_DATA.Partners.hasSlider && renderSlider(PRICING_DATA.Partners.value)}
                    {renderTable(PRICING_DATA.Partners.value)}
                  </Grid>
                </Slide>
              </TabPanel>
            </TabContext>
          </Grid>
          <Grid item md={1} />
        </Grid>
      </Grid>
    </>
  );
};

export default Pricing;

type Props = {
  row: Object,
  colors: Array,
  sliderValue: Number,
};

const CollapsableRow = ({ row, colors, sliderValue }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow key={row.title} className={classes.row}>
        <TableCell component="td" scope="row" className={classes.cell}>
          <Grid container>
            <Grid item xs={2}>
              {(row.subtitle || row.sublines) && (
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              )}
            </Grid>
            <Grid item xs={10} style={{ alignSelf: 'center' }}>
              {row.title}
            </Grid>
          </Grid>
        </TableCell>
        {row.cells?.map((cell, i) => (
          <TableCell
            key={JSON.stringify(cell)}
            align="center"
            component="th"
            scope="row"
            className={classes.cell}
            style={{ backgroundColor: colors[i] }}
          >
            {cell === true && <IconCheck className={classes.checkIcon} />}
            {cell === 'sliderValue' && `${sliderValue}`}
            {cell !== true && cell !== 'sliderValue' && cell}
          </TableCell>
        ))}
      </TableRow>
      {row.subtitle && (
        <TableRow key={`${row.name}-sub`} className={classes.row}>
          <TableCell className={classes.cell} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={open}>
              <Typography gutterBottom variant="body2" style={{ paddingBottom: '20px' }}>
                {row.subtitle}
              </Typography>
            </Collapse>
          </TableCell>
          {colors.map((col, i) => (
            <TableCell
              key={JSON.stringify(col)}
              className={classes.cell}
              style={{ backgroundColor: colors[i], paddingBottom: 0, paddingTop: 0 }}
            >
              <Collapse in={open}> </Collapse>
            </TableCell>
          ))}
        </TableRow>
      )}
      {row.sublines?.map(subline => (
        <TableRow key={`${row.name}-sub`} className={classes.row}>
          {subline.map((cell, i) => (
            <TableCell
              className={classes.cell}
              style={{
                backgroundColor: i > 0 ? colors[i - 1] : 'transparent',
                paddingBottom: 0,
                paddingTop: 0,
              }}
              align={i > 0 ? 'center' : 'left'}
            >
              <Collapse in={open}>
                <Typography gutterBottom variant="body2" style={{ paddingBottom: '20px' }}>
                  {cell}
                </Typography>
              </Collapse>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
